import React, { useState, useEffect } from 'react'
import { navigate } from 'gatsby'
import { signInWithToken } from '../api'
import { watch, local, dispatch } from '../model'

import Layout from '../components/layout'
import SEO from '../components/seo'

import './index.css'

const LoginPage = () => {
  const user = watch(local.user)
  const [signingIn, setSigningIn] = useState(false)

  const onSignedIn = (event: MessageEvent) => {
    if (event.data && event.data.type === 'signin-complete') {
      dispatch(signInWithToken)(event.data.token)
    }
  }

  useEffect(() => {
    if (user) {
      navigate('/dashboard', { replace: true })
    }
  }, [user])

  const onGitHubSignIn = () => {
    window.addEventListener('message', onSignedIn)
    window.open(
      `https://github.com/login/oauth/authorize?scope=user:email&client_id=${process.env.GATSBY_GITHUB_CLIENT_ID}`
    )
    setSigningIn(true)
  }

  const onGoogleSignIn = () => {
    window.addEventListener('message', onSignedIn)
    window.open(
      `https://accounts.google.com/o/oauth2/v2/auth?client_id=${process.env.GATSBY_GOOGLE_CLIENT_ID}&response_type=code&scope=openid%20email%20profile&redirect_uri=${process.env.GATSBY_API_URL}/oauth/google&access_type=offline`
    )
    setSigningIn(true)
  }
  return (
    <Layout>
      <SEO title="Lona" />
      {signingIn ? (
        'Signing in...'
      ) : (
        <div>
          <button onClick={onGitHubSignIn}>Login with GitHub</button>
          <button onClick={onGoogleSignIn}>Login with Google</button>
        </div>
      )}
    </Layout>
  )
}

export default LoginPage
